import Axios from "axios"

var BASEURL = process.env.REACT_APP_BASEURL
var QUICKERURL = process.env.REACT_APP_QUICKER_URL

export const GetDepositLimits = (Token) => {

    var Data = {
        "Token": Token,
    };

    return Axios.post(BASEURL + "api/Deposits/GetDepositLimits", Data).then((res) => res.data);
}

export const GetExpressLimits = (Token) => {

    var Data = {
        "Token": Token,
    };

    return Axios.post(BASEURL + "api/Deposits/GetExpressLimits", Data).then((res) => res.data);
}

export const GetQuickerDirectZelle = (Token, ProcessorID) => {

    var Data = {
        "Token": Token,
        "URL": QUICKERURL,
        "ProcessorID": ProcessorID
    };

    return Axios.post(BASEURL + "api/quicker/QuickerDirectZelle", Data).then((res) => res.data);
}

export const QuickerInitiateTransaction = (Token, ProcessorID, Amount, BankAccountID, BonusList) => {

    var Data = {
        "Token": Token,
        "ProcessorID": ProcessorID,
        "Amount": Amount,
        "BankAccountID": BankAccountID,
        "SendInstructions": true,
        "isDirectDeposit": true,
        "BonusList": BonusList
    };

    return Axios.post(BASEURL + "api/quicker/QuickerInitiateTransaction", Data).then((res) => res.data);
}

export const CryptoDeposit = (Token, CryptoName, InstanceID, Amount, CurrencyCode, returnURL, statusURL, TransactionID, IPv4Address, Network, BonusList) => {

    var Data = {
        "Token": Token,
        "CryptoName": CryptoName,
        "InstanceID": InstanceID,
        "Amount": Amount,
        "CurrencyCode": CurrencyCode,
        "returnURL": returnURL,
        "statusURL": statusURL,
        "TransactionID": TransactionID,
        "IPv4Address": IPv4Address,
        "Network" : Network,
        "BonusList": BonusList
    };

    return Axios.post(BASEURL + "api/Deposits/CryptoDeposit", Data, {
        headers: {
            'Content-Type': 'application/json'
        }}).then((res) => res.data);
}


export const GetCardAddress = (Token, Last4) => {

    var TokenData = {
        "Token": Token,
        "Last4": Last4
    };

    return Axios.post(BASEURL + "api/Deposits/GetCardAddress", TokenData).then((res) => res.data);
}

export const Is3dTransaction = (formData) => {

    return Axios.post(BASEURL + "api/Deposits/Is3dTransaction", formData).then((res) => res.data);
}

export const CreditCardDeposit = (formData) => {

    return Axios.post(BASEURL + "api/Deposits/CreditCardDeposit", formData).then((res) => res.data);
}

export const GetBonuses = (Token, Processorname) => {

    var RequestData = {
        "Token": Token,
        "ProcessorName": Processorname
    };

    return Axios.post(BASEURL + "api/Deposits/GetBonuses ", RequestData).then((res) => res.data);
}

export const CreditCardDepositExpress = (Token, TransactionID, RegisteredID, Amount, CurrencyCode, IPv4Address, Comments, CSID, InstanceID, Source, User, DepositSource, BlackBox, BonusList, Status3DURL, Udf1) => {

    var RequestData = {
        "Token": Token,
        "TransactionID": TransactionID,
        "RegisteredID": RegisteredID,
        "Amount": Amount,
        "CurrencyCode": CurrencyCode,
        "IPv4Address": IPv4Address,
        "Comments": Comments,
        "CSID": CSID,
        "InstanceID": InstanceID,
        "Source": Source,
        "User": User,
        "DepositSource": DepositSource,
        "BlackBox": BlackBox,
        "BonusList": BonusList,
        "Status3DURL": Status3DURL,
        "Udf1": Udf1
    }

    return Axios.post(BASEURL + "api/Deposits/CreditCardDepositExpress", RequestData).then((res) => res.data);
}

export const GetPreferredLimits = (Token) => {

    var Data = {
        "Token": Token,
    };

    return Axios.post(BASEURL + "api/Deposits/GetPreferredLimits", Data).then((res) => res.data);
}

export const EWalletDeposit = (formData) => {

    return Axios.post(BASEURL + 'api/Deposits/EWalletDeposit', formData).then((res) => res.data);
}

export const GetAlias = (Token) => {
    var Data = {
        "Token": Token,
    };
    return Axios.post(BASEURL + 'api/Deposits/PersontoPersonAlias', Data).then((res) => res.data);
}

export const GetPendingP2P = (Token, P2POption) => {
    var Data = {
        "Token": Token,
        "P2POption": P2POption
    };
    return Axios.post(BASEURL + 'api/Authentication/GetPendingP2P', Data).then((res) => res.data);
}

export const PersontoPersonOptions = (Token) => {
    var Data = {
        "Token": Token,
    };
    return Axios.post(BASEURL + 'api/Deposits/PersontoPersonOptions', Data).then((res) => res.data);
}

export const PersontoPersonConfirm = (formData) => {
    
    return Axios.post(BASEURL + 'api/Deposits/PersontoPersonConfirm', formData).then((res) => res.data);
}

export const PersontoPersonDeposit = (formData) => {

    return Axios.post(BASEURL + 'api/Deposits/PersontoPersonDeposit', formData).then((res) => res.data);
}

export const GetCryptoNetworks = (Token, CryptoName) =>{
    var Data = {
        "Token": Token,
        "CryptoName": CryptoName,
    };
    return Axios.post(BASEURL + 'api/Others/GetCryptoNetworks', Data).then((res) => res.data);
}

