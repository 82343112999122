export function onlyNumbers(evt) {
    var e = window.event || evt; // for trans-browser compatibility
    var charCode = e.which || e.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57))
        evt.preventDefault();

    return true;
}

export function onlyNumbersMaxLength(evt, ele, max) {
    var e = window.event || evt; // for trans-browser compatibility
    var charCode = e.which || e.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57))
        evt.preventDefault();

    if (ele.value.length == max)
        evt.preventDefault();

    return true;
}


export function cc_format(value) {
    const v = value
        .replace(/\s+/g, "")
        .replace(/[^0-9]/gi, "")
        .substr(0, 16);
    const parts = [];

    for (let i = 0; i < v.length; i += 4) {
        parts.push(v.substr(i, 4));
    }

    return parts.length > 1 ? parts.join(" ") : value;
}

export function isLeapYear(year) {
    return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
}

export function getDaysInMonth(year, month) {
    return [31, (isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
}

export function addMonths(date, value) {
    var d = new Date(date),
        n = date.getDate();
    d.setDate(1);
    d.setMonth(d.getMonth() + value);
    d.setDate(Math.min(n, getDaysInMonth(d.getFullYear(), d.getMonth())));
    return d;
}

export function validateExp(strDate) {
    var enteredDate = new Date(strDate);
    var currentDate = new Date().getTime();
    var newDate = addMonths(enteredDate, 1).getTime();
    return (newDate > currentDate);
}

export function stringDate(mm, dd, yy) {
    var separator = '/';
    return mm + separator + dd + separator + yy;
}

export function dateRange(value, min, max) {
    return ((value >= min) && (value <= max));
}

export function validateEnteredDate(element, min, max) {
    if (!dateRange(element.value, min, max)) {
        element.value = '';
        return false;
    }
    return true;
}

export function dateValidator(evt) {
    var e = window.event || evt;
    var charCode = e.which || e.keyCode;
    return (charCode == 45 || (charCode > 46 && charCode < 58));
}

export function isDate(str) {
    var parms = str.split(/[\.\-\/]/);
    var yyyy = parseInt(parms[2]);
    var mm = parseInt(parms[0]);
    var dd = parseInt(parms[1]);
    var date = new Date(yyyy, mm - 1, dd);
    return mm === (date.getMonth() + 1) && dd === date.getDate() && yyyy === date.getFullYear();
}

export function BonusString(Array) {    
    var bonuslist = '';
    if (Array !== undefined) {
        for (var i = 0; i < Array.length; i++) {
            if (Array[i].isChecked) {
                bonuslist += Array[i].BonusCode + ', ';
            }
        }
    }
    
    return bonuslist;
}

export function checkDOB(isMissing, dateEntered) {
    if (isMissing) {
        if (isDate(dateEntered)) {
            var d = new Date(dateEntered);
            var years = d.getFullYear();
            var diffYears = (new Date().getFullYear()) - years;
            if (Number.isNaN(diffYears))
                return 'You need to enter a valid date with the following format: MM/DD/YYYY.';
            if (diffYears < 18 || diffYears > 95)
                return 'Date entered should be at least 18 years old and no older than 95 years.';
        }
        else {
            return 'You need to enter a valid date with the following format: MM/DD/YYYY.';
        }
    }
    return null;
}

export function luhnChk(luhn) {
    var len = luhn.length,
        mul = 0,
        prodArr = [[0, 1, 2, 3, 4, 5, 6, 7, 8, 9], [0, 2, 4, 6, 8, 1, 3, 5, 7, 9]],
        sum = 0;

    while (len--) {
        sum += prodArr[mul][parseInt(luhn.charAt(len), 10)];
        mul ^= 1;
    }

    return sum % 10 === 0 && sum > 0;
};
/*

export function handleFpKey(fpKey) {
    var xhr = new XMLHttpRequest();
    document.getElementById('fp_key').innerHTML = fpKey;
}
var fhcnt = 0;
export function GetFingerPrint() {
    fhcnt++;
    var fpk = window.fppixel && fppixel.fpk;
    if (fpk) {
        handleFpKey(fpk);
    } else {
        __fpiGlobalVariables = {
            __fpiCb: handleFpKey
        }
        if (fhcnt <= 20) {
            setTimeout(GetFingerPrint, 1000);
        }
    }
    return fpk;
}
*/
//GetFingerPrint();
