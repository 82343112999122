import { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import clientInformation from '../login/ClientInfo';
import Alerts from '../helpers/Alerts';
import { IsValidToken } from "../../services/AuthenticationService";
import { GetPayoutsLimits } from "../../services/PayoutService";
import { GetExpressLimits } from "../../services/DepositService";
import Loading from '../helpers/Loader';

export const PayoutMethods = () => {
    const clientInfo = clientInformation();
    let MAXLIMIT = process.env.REACT_APP_MaxLimit;
    const [isLoading, setIsLoading] = useState(true);
    const [arrayPayouts, setArrayPayouts] = useState([]);
    const [disableExpress, setDisableExpress] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {

        IsValidToken(localStorage.getItem('Token')).then(async function (response) {
            await PayoutsLimits();
            await ExpressDepositsLimits();
        }

        ).catch(function (error) {
            navigate('/expired/');
        })

    }, []);

    async function ExpressDepositsLimits() {
        await GetExpressLimits(localStorage.getItem('Token')).then(async function (response) {
            if (response.Authentication.length === 0) {
                setDisableExpress(true);
            }
        });
    }

    async function PayoutsLimits() {
        await GetPayoutsLimits(localStorage.getItem('Token'), clientInfo.CurrencyCode, clientInfo.AvailableBalance).then(async function (response) {

            var i = 0;
            const filtered = response.Authentication.map(item => {

                const {
                    IconURL,
                    ImageName,
                    MaxPayout,
                    MinPayout,
                    PaymentType,
                    PayoutID,
                    PayoutName,
                    ProcessorID
                } = item;
                if (item.PaymentType.toLowerCase() === "credit card" ||
                    item.PaymentType.toLowerCase() === "crypto" ||
                    item.PaymentType.toLowerCase() === "money gram" ||
                    item.PaymentType.toLowerCase() === "generic"
                ) {
                    return {
                        Key: i++,
                        IconURL,
                        ImageName,
                        MaxPayout,
                        MinPayout,
                        PaymentType,
                        PayoutID,
                        PayoutName,
                        ProcessorID
                    };
                }
            });
            await setArrayPayouts(filtered.filter(function (element) { return element !== undefined; }));
            setIsLoading(false);
            await localStorage.setItem('arrayPayoutsMethods', JSON.stringify(filtered.filter(function (element) { return element !== undefined; })));
        }).catch(function (error) {
            setIsLoading(false);
            clientInfo.Error = false;
            Alerts.ShowAlert('Error', 'Error getting payout limits info: ' + error.response.data.Authentication.ErrorDescription, 'error');


        })
    }

    function GetFormURL(Key) {
        if (arrayPayouts[Key].PaymentType.toLowerCase() === "credit card")
            return "/payouts/creditcard/"
        else if (arrayPayouts[Key].PaymentType.toLowerCase() === "crypto")
            return "/payouts/crypto/" + arrayPayouts[Key].PayoutID
        else if (arrayPayouts[Key].PaymentType.toLowerCase() === "money gram")
            return "/payouts/moneygram/"
        else if (arrayPayouts[Key].PaymentType.toLowerCase() === "generic") {
            return "/payouts/generic/" + arrayPayouts[Key].ProcessorID
        }
        else
            return "";
    }

    if (isLoading) {
        return <Loading />;
    }

    const handleMethodSelect = (name) => {
        navigate(`/deposits/${name}`);
    }

    const handleExpressSelect = () => {
        navigate(`/main/`);
    }

    return (
        <div className="mt-3 componentload">
            <div className="title">
                <div className="container">
                    <h4 className="txt-primary payout-title-color mb-4">Payout methods</h4>
                </div>
            </div>
            <div className="row" style={{display:'none'}}>
                {(disableExpress == false) ?
                    <div className="col-xxl-6 col-md-6 col-sm-6 col-xs-6">
                        <div onClick={() => handleExpressSelect()} className="box-deposit express-color mb-4 text-center text-wrap" >
                            <span className="othermethod-text text-center">EXPRESS DEPOSIT</span>
                        </div>
                    </div>
                    :
                    <div className="col-xxl-6 col-md-6 col-sm-6 col-xs-6" title="You need to deposit first to have this option available">
                        <div className="box-deposit express-color-disabled mb-4 text-center text-wrap" >
                            <span className="othermethod-text text-center">EXPRESS DEPOSIT</span>
                        </div>
                    </div>
                }
                <div className="col-xxl-6 col-md-6 col-sm-6 col-xs-6">
                    <div onClick={() => handleMethodSelect('')} className="box-deposit  othermethod-color mb-4 text-center text-wrap " >
                        <span className="othermethod-text text-center ">USE OTHER DEPOSIT METHODS</span>
                    </div>
                </div>
            </div>
            <div className="row">
                {arrayPayouts.map((item) => (
                    <div className="col-xxl-4 col-md-4 col-sm-6 col-xs-6">
                        <Link key={item.Key} to={GetFormURL(item.Key)} className="box-deposit  mb-4">
                            <div className="row">
                                <div className="col-md-3 col-sm-12">
                                    <span className="icon-single">
                                        {(item.PaymentType === 'Generic') ?
                                            <img alt="" src={"data:image/svg+xml;base64," + item.ImageName} />
                                            :
                                            <img alt="" src={"/svg/" + item.ImageName + ".svg"} />
                                        }
                                    </span>
                                </div>
                                <div className="col-md-8 col-sm-12 text-center limit-text">
                                    <span>Min:${item.MinPayout === 0 ? 1 : item.MinPayout} - Max:{item.MaxPayout === 0 ? 'No Limit' : '$' + item.MaxPayout}</span>
                                </div>
                                <div className="col-md-3 limit-text d-none d-md-block ">

                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
            <div className="row">
                <div className="col-12 actionbuttons mt-3">
                    <button type="button" className="btn btn-secondary btn-gray btn-back-order mb-4 " onClick={() => navigate('/deposits/')}>BACK</button>
                </div>
            </div>
        </div>
    )
}