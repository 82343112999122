import { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import Alerts from '../helpers/Alerts';
import { IsValidToken } from "../../services/AuthenticationService";

export const PriorityMethods = () => {
    const navigate = useNavigate();
    const [arrayPriorityDeposits, setArrayPriorityDeposits] = useState(JSON.parse(localStorage.getItem('ArrayPriorityMethods')));

    useEffect(() => {

        IsValidToken(localStorage.getItem('Token')).then(async function (response) {
            
        }

        ).catch(function (error) {
            navigate('/expired/');
        })

    }, []);
        
    function GetFormURL(Key) {
        if (arrayPriorityDeposits[Key].PaymentType.toLowerCase() == "")
            return "/deposits/creditcard/" + arrayPriorityDeposits[Key].DepositID
        else if (arrayPriorityDeposits[Key].PaymentType.toLowerCase() == "crypto")
            return "/deposits/crypto/" + arrayPriorityDeposits[Key].DepositID
        else if (arrayPriorityDeposits[Key].PaymentType.toLowerCase() == "ewallet")
            switch (arrayPriorityDeposits[Key].ImageName.toLowerCase()) {
                case "quickerzelle":
                    {
                        return "/deposits/zelle/";
                        break;
                    }
                default: {
                    return "/deposits/ewallet/" + arrayPriorityDeposits[Key].DepositID;
                    break;
                }
            }
        else if (arrayPriorityDeposits[Key].PaymentType.toLowerCase() == "money gram")
            return "/deposits/moneygram/"
        else
            return "";
    }
    
    return (
        <div className="componentload">
        {arrayPriorityDeposits.length != 0 && <div className="mt-5">
            < div className="title">
                <div className="container">
                    <h4 className="txt-primary text-title-color">Most popular methods</h4>
                </div>
            </div>
            <div className="row">
                {arrayPriorityDeposits.map((item) => (
                    <div className="col-xxl-4 col-md-4 col-sm-4 col-xs-4">
                        <Link key={item.Key} to={GetFormURL(item.Key)} className="box-deposit box-green mb-2 mt-2">
                            <div className="row">
                                <div className="col-md-3 col-sm-12">
                                    <span className="icon-single">
                                        <img alt="" src={"/svg/" + item.ImageName + ".svg"} />
                                    </span>
                                </div>
                                <div className="col-md-8 col-sm-12 text-center limit-text">
                                    <span className="d-none d-md-block">Min:${item.MinDeposit == 0 ? 1 : item.MinDeposit} - Max:{item.MaxDeposit == 0 ? 'No Limit' : '$' + item.MaxDeposit}</span>

                                </div>
                                <div className="col-md-3 limit-text d-none d-md-block ">

                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
            </div>}
        </div>
        );
}