import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Alerts from '../helpers/Alerts';
import { IsValidToken, GetCustomerInfo, CheckCustomerData } from "../../services/AuthenticationService";
import { onlyNumbers, BonusString } from '../helpers/common';
import Bonus from '../Bonus';
import { GetAlias, GetPendingP2P, PersontoPersonOptions, PersontoPersonConfirm, PersontoPersonDeposit } from "../../services/DepositService";
import Loading from '../helpers/Loader';
import clientInformation from '../login/ClientInfo';
import { isMobile } from 'react-device-detect';
import { MissingAccountInfo } from "../deposit/MissingAccountInfo.jsx";

export const P2PMethods = () => {
    const clientInfo = clientInformation();
    let MAXLIMIT = process.env.REACT_APP_MaxLimit;
    let INSTANCEID = process.env.REACT_APP_InstanceID;
    const { id } = useParams();
    const [arrayRegularDeposits, setRegularDeposits] = useState(JSON.parse(localStorage.getItem('ArrayRegularMethods')).filter(function (element) { return element.DepositID.toLowerCase() == 'moneygram'; })[0]);
    const navigate = useNavigate();
    const [arrayAlias, setArrayAlias] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [arrayP2POptions, setArrayP2POptions] = useState([]);
    const [arrayPendingP2P, setArrayPendingP2P] = useState([]);
    const [arrayCustomerInfo, setArrayCustomerInfo] = useState([]);
    const [TransactionID, setTransactionID] = useState('');
    const [Alias, setAlias] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [isValidate, setIsValidate] = useState(true);

    useEffect(() => {
        IsValidToken(localStorage.getItem('Token')).then(async function (response) {
            doCheckCustomerData();
            await GetAlias(localStorage.getItem('Token')).then(async function (response) {
                var i = 0;
                const filtered = response.Authentication.Alias.map(item => {
                    const {
                        Value,
                        Name } = item;

                    return { Key: i++, Value, Name }
                });
                setArrayAlias(filtered);
            });
        })
            .catch(function (error) {
                navigate('/expired/');
            })
        doGetPendingP2P();
        getPersontoPersonOptions();
        CustomerInfo();

    }, [])

    if (isLoading) {
        return <Loading />;
    }

    function handleResponseFromComponent(response) {
        setIsValidate(response);
    }

    async function doCheckCustomerData() {
        await CheckCustomerData(localStorage.getItem('Token'), arrayRegularDeposits.DepositID).then(async function (response) {
            if (!response.Authentication.AllComplete) {
                setIsValidate(true);
            }
            else {
                setIsValidate(false);
            }
        })
    };

    if (isValidate) {
        return <MissingAccountInfo Processor={arrayRegularDeposits.DepositID} isValidate={handleResponseFromComponent.bind(isValidate)} />;
    }

    async function doGetPendingP2P() {
        await GetPendingP2P(localStorage.getItem('Token'), arrayRegularDeposits.DepositID).then(async function (response) {
            var i = 0;
            const filtered = response.Authentication.tnxList.map(item => {
                const {
                    Address,
                    Amount,
                    ReceiverName,
                    TransactionID,
                    dateCreated} = item;

                return {
                    Key: i++,
                    Address,
                    Amount,
                    ReceiverName,
                    TransactionID,
                    dateCreated }
            });
            setArrayPendingP2P(filtered);
            
        })
            .catch(function (error) {

                
            })

    }

    async function getPersontoPersonOptions() {
        await PersontoPersonOptions(localStorage.getItem('Token')).then(async function (response) {
            setArrayP2POptions(response.Authentication);
            
        })
            .catch(function (error) {


            })

    }

    async function CustomerInfo() {
        await GetCustomerInfo(localStorage.getItem('Token')).then(function (response) {
            if (response.Authentication.ErrorDescription != "Invalid input data") {
                setArrayCustomerInfo(response.Authentication);

            }
            else {
                
                Alerts.ShowAlert('Error', 'Error getting customer info: ' + response.Authentication.ErrorDescription, 'error');

            }
        })
            .catch(function (error) {
                
                Alerts.ShowAlert('Error', 'Error getting customer info: ' + error.response.data.Authentication.ErrorDescription, 'error');
            })
    }

    async function doPersontoPersonConfirm(formData) {
        await PersontoPersonConfirm(formData).then(function (response) {

            if (isMobile)
                Alerts.ShowAlertFullScreen('Deposit Status', response.Authentication.HtmlResponse, 'info');
            else
                Alerts.ShowAlert('Deposit Status', response.Authentication.HtmlResponse, 'info');
        })
            .catch(function (error) {

                setIsLoading(false);
               
                if (isMobile)
                    Alerts.ShowAlertFullScreen('Error', error.response.data.Authentication.ErrorDescription, 'error');
                else
                    Alerts.ShowAlert('Error', error.response.data.Authentication.ErrorDescription, 'error');
            })
    }

    async function doPersontoPersonDeposit(formData) {
        await PersontoPersonDeposit(formData).then(function (response) {

            if (isMobile)
                Alerts.ShowAlertFullScreen('Deposit Status', response.Authentication.HtmlResponse, 'info');
            else
                Alerts.ShowAlert('Deposit Status', response.Authentication.HtmlResponse, 'info');
        })
            .catch(function (error) {

                setIsLoading(false);

                if (isMobile)
                    Alerts.ShowAlertFullScreen('Error', error.response.data.Authentication.ErrorDescription, 'error');
                else
                    Alerts.ShowAlert('Error', error.response.data.Authentication.ErrorDescription, 'error');
            })
    }

    const submitOtherDepositStep2 = async (event) => {
        event.preventDefault();
        if (!isProcessing) {
            var data = new FormData(event.target);
            let {ControlNumber} = Object.fromEntries(data.entries());
            if (ControlNumber != '') {
                setIsLoading(true);
                setIsProcessing(true);
                var formData = {

                    "Token": localStorage.getItem('Token'),
                    "Amount": arrayPendingP2P.find(function (array) { return array.TransactionID == TransactionID }).Amount,
                    "CurrencyCode": 'USD',
                    "IPv4Address": clientInfo.IPAddress,
                    "InstanceID": INSTANCEID,
                    "PayMethod": arrayRegularDeposits.DepositID,
                    "TransactionID": TransactionID,
                    "MTCN": ControlNumber

                };
                await doPersontoPersonConfirm(formData);
                setIsProcessing(false);
                setIsLoading(false);
                
            }
            else {
                Alerts.ShowAlert('Warning', 'Control Number required', 'warning');
            }

        }
    }

    function getFullName(Firstname, Secondname, Lastname) {

        if (arrayP2POptions.HideCustomerInfoCashier == 0) {
            var names = [
                Firstname,
                (Secondname != null || Secondname != undefined) ? Secondname : null,
                Lastname
            ];

            const filteredNames = names.filter(function (element) { return element !== null && element !== ''; });

            return filteredNames.join(" ");
        }
        else {
            return Alias;
        }
    }

    const submitOtherDepositStep1 = async (event) => {
        event.preventDefault();
        if (!isProcessing) {
            var data = new FormData(event.target);
            let { Amount, Firstname, Secondname, Lastname } = Object.fromEntries(data.entries());
                if (Amount != '') {
                    setIsLoading(true);
                    setIsProcessing(true);
                    var formData = {
                        "Token": localStorage.getItem('Token'),
                        "Amount": Amount,
                        "CurrencyCode": 'USD',
                        "IPv4Address": clientInfo.IPAddress,
                        "InstanceID": INSTANCEID,
                        "PayMethod": arrayRegularDeposits.DepositID,
                        "FullName": getFullName(Firstname, Secondname, Lastname),
                        "BonusList": BonusString(JSON.parse(localStorage.getItem('ArrayBonus'))),
                    };
                    await doPersontoPersonDeposit(formData);
                    setIsProcessing(false);
                    setIsLoading(false);
                    navigate('/deposits/');
                }
                else {
                    Alerts.ShowAlert('Warning', 'Control Number required', 'warning');
                }            

        }

    }

    return (
        <div className="componentload">
            <div className="row m-4">
                <span className="icon-single-left txt-primary">
                    <img style={{ width: "260px", height: "100px" }} src={"/svg/" + arrayRegularDeposits.ImageName + ".svg"} /> <span className="d-none d-md-block">Deposit using Moneygram</span>
                </span>
            </div>
            <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12 " >
                    <form onSubmit={submitOtherDepositStep2} >
                    <div className="row mb-4">
                        <div className="col-md-1 d-none d-md-block"></div>
                        <div className="col-md-10 col-sm-12">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header ">
                                        <button className="accordion-button  text-center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <i className="fa fa-info-circle fa-2x " aria-hidden="true"></i> Click here if you have a&nbsp;&nbsp;<b>Control Number</b>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse hide" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                                        <label className="mt-2" >My Requested Receivers:</label>
                                                    </div>

                                                    <div className="col-md-9 col-sm-12 col-xs-12">
                                                        <table className="transaction-result table">
                                                            <thead className="bg-primary">
                                                                <tr>
                                                                    <th><center className="">Tnx ID</center></th>
                                                                    <th className="text-center">Receiver</th>
                                                                    <th className="text-center">Amount</th>
                                                                    <th><center className="d-none d-md-block">Address</center></th>
                                                                </tr>
                                                            </thead>
                                                            {arrayPendingP2P.map((item) => (
                                                            <tbody>
                                                                
                                                                <tr key={item.Key} ng-repeat="item in TransactionHistoryList" className="ng-scope">
                                                                        <td className="" onChange={(event) => { setTransactionID(event.target.value) }}>
                                                                            <input type="radio" name="radgroup" ng-model="tnxlist.selectedOption" ng-value="item.TransactionID" className="ng-pristine ng-untouched ng-valid ng-not-empty" value={item.TransactionID} />{item.TransactionID}
                                                                    </td>
                                                                        <td className="text-center">{item.ReceiverName}</td>
                                                                        <td className="text-center">{item.Amount}</td>
                                                                        <td><center className="d-none d-md-block">{item.Address}</center></td>
                                                                </tr>
                                                                                                                           
                                                                </tbody>
                                                            ))}
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="row mb-4">
                                                    <div className="col-md-3 col-sm-12">
                                                        <label for="controlnumber" >Control Number:</label><br />
                                                    </div>
                                                    <div className="col-md-4 col-sm-12">
                                                            <input type="text" onKeyPress={(event) => onlyNumbers(event)} className="form-control border-teal" aria-label="Control Number" placeholder="Control Number" name="ControlNumber" maxlength="8" ng-minlength="8" />
                                                        <span className="text-danger">Control number required.</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <button type="submit" className="btn btn-deposit btn-deposit-order mb-4" ng-click="submitOtherDepositStep2()">Complete</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 d-none d-md-block"></div>
                    </div>
                    </form>
                    <form onSubmit={submitOtherDepositStep1} >
                    <div>
                    <div className="row">
                        <div className="col-md-1 d-none d-md-block"></div>
                        <div className="col-md-5 col-sm-12" style={{ verticalAlign: "middle" }}>
                            <div className="row">
                                <label className="d-none d-md-block">Amount</label>
                                <div className="col-md-12 m3 ">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-currency-cards d-none d-md-block" id="lblCurrency">
                                                <select className="form-control selectcurrency" disabled name="CurrencyCode" required value={clientInfo.CurrencyCode == 'mBTC' ? 'USD' : clientInfo.CurrencyCode}>
                                                    <option value="" className="">Currency</option>
                                                    <option selected value="USD">USD</option>
                                                    <option value="EUR">EUR</option>
                                                    <option value="GBP">GBP</option>
                                                    <option value="CAD">CAD</option>
                                                </select>
                                            </span>
                                        </div>
                                        <input type="number" onKeyPress={(event) => onlyNumbers(event)} aria-label="Amount (to the nearest dollar)" className="form-control" id="amount" aria-describedby="lblCurrency" placeholder="Amount" max={arrayRegularDeposits?.MaxDeposit === 0 ? MAXLIMIT : arrayRegularDeposits?.MaxDeposit} min={arrayRegularDeposits?.MinDeposit === 0 ? 1 : arrayRegularDeposits?.MinDeposit} name="amount" required="" />
                                    </div>
                                </div>
                                <div className="d-flex flex-row ml-2 limit-text-center ">
                                    Deposit Limits: Minimum {arrayRegularDeposits?.MinDeposit == 0 ? 1 : arrayRegularDeposits?.MinDeposit} USD Maximum {arrayRegularDeposits?.MaxDeposit == 0 ? 'No Limit' : arrayRegularDeposits?.MaxDeposit + ' USD'}
                                </div>
                             </div>   
                                </div>

                                {arrayP2POptions.HideCustomerInfoCashier == 0 && <div className="col-md-1 d-none d-md-block"></div>}
                                {arrayP2POptions.HideCustomerInfoCashier == 0 && <div className="col-md-5 col-sm-12" style={{ verticalAlign: "middle" }}>
                                    <div className="row mb-3">
                                        <label className="d-none d-md-block">Name</label>
                                                <div>
                                            <input type="text" className="form-control" name="Firstname" ng-model="othForm.Firstname"
                                                        aria-label="First Name" placeholder="First Name" ng-required="P2POptions.HideCustomerInfoCashier == 0 " />
                                                    <input type="text" className="form-control" name="Lastname" ng-model="othForm.Lastname"
                                                        aria-label="last Name" placeholder="Last Name" ng-required="P2POptions.HideCustomerInfoCashier == 0 " />
                                                </div>
                                        </div>   
                                </div>}
                                {arrayP2POptions.HideCustomerInfoCashier == 0 && <div className="col-md-1 d-none d-md-block"></div>}
                                {arrayP2POptions.HideCustomerInfoCashier == 0 && <div className="col-md-5 col-sm-12" style={{ verticalAlign: "middle" }}>
                                    <div className="row">
                                        <div className="col-md-12 m3 ">
                                            <div className="input-group mb-3  ">
                                                <div className="input-group-prepend ">
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {arrayP2POptions.HideCustomerInfoCashier == 0 && <div className="col-md-1 d-none d-md-block"></div>}
                                {arrayP2POptions.HideCustomerInfoCashier == 0 && <div className="col-md-5 col-sm-12 mb-3" style={{ verticalAlign: "middle" }}>
                                    <div className="row">
                                        <label className="d-none d-md-block">Second name (Optional)</label>
                                        <div>
                                            <input type="text" class="form-control" name="Secondname" ng-model="othForm.Secondname"
                                                aria-label="second Lastname" placeholder='SecondLastName' />
                                        </div>
                                    </div>
                                </div>}
                        {arrayP2POptions.HideCustomerInfoCashier == 1 && <div className="col-md-5 col-sm-12">
                            <div className="row">
                                <label for="p2palias" className="col-md-2 col-sm-12 align-middle">Alias:</label>
                                <div className="col-md-12 col-sm-12">
                                    <select className="form-control form-select border-teal " name="p2palias" required="required"
                                        onChange={(event) => { setAlias(event.target.value) }}
                                        tooltip-placement="bottom" tooltip-trigger="focus" tooltip-class="customClass" tooltip="Please Choose">
                                        <option value="">- Please Choose -</option>
                                                {arrayAlias.map((item) => <option key={item.Key} value={item.Name}>{item.Name}</option>)}
                                        
                                    </select>
                                </div>
                            </div>
                        </div>}
                        <div className="col-md-1 d-none d-md-block"></div>
                    </div>
                    <div className="row mb-3" >
                        <div className="col-md-1 d-none d-md-block"></div>

                        <div className="col-md-1 d-none d-md-block"></div>
                    </div>
                    <div className="row">
                        <div className="col-md-1 d-none d-md-block"></div>
                        <div className="col-md-10 col-sm-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row  mb-3">
                                        <label for="country" className="col-md-4 col-sm-12">Country:</label>
                                        <div className="col-md-8 col-sm-12 ">
                                            <input type="text" className="form-control color-gray border-teal" name="country" aria-label="country" value={arrayCustomerInfo.Country} placeholder={arrayCustomerInfo.Country == '' ? "Country" : arrayCustomerInfo.Country}disabled="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row  mb-3">
                                        <label for="state" className="col-md-4 col-sm-12">State / Prov:</label>
                                        <div className="col-md-8 col-sm-12">
                                            <input type="text" className="form-control color-gray border-teal" name="state" aria-label="state" value={arrayCustomerInfo.State} placeholder={arrayCustomerInfo.State == '' ? "State / Prov" : arrayCustomerInfo.State} disabled="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 d-none d-md-block"></div>
                    </div>
                    <div className="row">
                        <div className="col-md-1 d-none d-md-block"></div>
                        <div className="col-md-10 col-sm-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row  mb-3">
                                        <label for="city" className="col-md-4 col-sm-12">City:</label>
                                        <div className="col-md-8 col-sm-12">
                                            <input type="text" className="form-control color-gray border-teal" name="city" aria-label="city" value={arrayCustomerInfo.City} placeholder={arrayCustomerInfo.City == '' ? "City" : arrayCustomerInfo.City} disabled="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row  mb-3">
                                        <label for="phone" className="col-md-4 col-sm-12">Phone:</label>
                                        <div className="col-md-8 col-sm-12">
                                            <input type="text" className="form-control color-gray border-teal" name="phone" aria-label="phone" value={arrayCustomerInfo.Phone} placeholder={ arrayCustomerInfo.Phone  == '' ? "Phone" : arrayCustomerInfo.Phone } disabled="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 d-none d-md-block"></div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-1 "></div>
                        <div className="col-md-10 col-sm-12 col-xs-12 text-center">
                            <span className="">
                                Please note your person to person instructions will be valid for next 72 hours, after this time you will have to request new ones.
                            </span>
                        </div>
                        <div className="col-md-1 d-none d-md-block"></div>
                    </div>
                
                <Bonus />
                <div className="col-md-12 mb-3-small mt-4 actionbuttons">
                    <button type="button" className="btn btn-secondary btn-gray btn-back-order" onClick={() => navigate('/deposits/')}>BACK</button>
                     <button type="submit" className="btn btn-deposit btn-deposit-order mb-4 submit">GET NEW INSTRUCTIONS</button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>



        </div>
    );
}