import { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import clientInformation from '../login/ClientInfo';
import Alerts from '../helpers/Alerts';
import { IsValidToken } from "../../services/AuthenticationService";
import { GetDepositLimits, GetExpressLimits } from "../../services/DepositService"
import Loading from '../helpers/Loader';

export const RegularMethods = () => {
    const clientInfo = clientInformation();
    const [isLoading, setIsLoading] = useState(true);
    const [arrayDeposits, setArrayDeposits] = useState([]);
    const [disableExpress, setDisableExpress] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {

        IsValidToken(localStorage.getItem('Token')).then(async function (response) {
            await DepositsLimits();
            await ExpressDepositsLimits();
        }

        ).catch(function (error) {
            navigate('/expired/');
        })

    }, []);

    async function ExpressDepositsLimits() {
        await GetExpressLimits(localStorage.getItem('Token')).then(async function (response) {
            if (response.Authentication.length === 0) {
                setDisableExpress(true);
            }
        });
    }

    async function DepositsLimits() {
        await GetDepositLimits(localStorage.getItem('Token')).then(async function (response) {
            var i = 0;
            const filtered = response.Authentication.map(item => {

                const {
                    IconURL,
                    DepositName,
                    DepositID,
                    CreditCardTypeID,
                    PaymentType,
                    ImageName,
                    MinDeposit,
                    MaxDeposit,
                    ProcessorID
                } = item;
                if ((item.PaymentType === "Credit Card" ||
                    item.PaymentType === "Crypto" ||
                    item.PaymentType === "Western Union" ||
                    item.PaymentType === "Money Gram" ||
                    item.PaymentType === "generic")
                    ||
                    (item.PaymentType === "EWallet"
                        && (item.ImageName === "neteller" ||
                            item.ImageName.toLowerCase() === "paysafecard" ||
                            item.ImageName.toLowerCase() === "paypal" ||
                            item.ImageName.toLowerCase() === "braintree" ||
                            item.ImageName.toLowerCase() === "googlepay" ||
                            item.ImageName.toLowerCase() === "applepay" ||
                            item.ImageName.toLowerCase() === "premieret" ||
                            item.ImageName.toLowerCase() === "premierint" ||
                            item.ImageName.toLowerCase() === "premierplus" ||
                            item.ImageName.toLowerCase() === "pagomovil" ||
                            item.ImageName.toLowerCase() === "wipay" ||
                            item.ImageName.toLowerCase() === "quickervoucher" ||
                            item.ImageName.toLowerCase() === "quickerzelle" ||
                            item.ImageName.toLowerCase() === "apexglobal" ||
                            item.ImageName.toLowerCase() === "marketusa" ||
                            item.ImageName.toLowerCase() === "changelly" ||
                            item.ImageName.toLowerCase() === "quickbuy" ||
                            item.ImageName.toLowerCase() === "upay"
                        ))) {
                    return {
                        Key: i++,
                        IconURL,
                        DepositName,
                        DepositID,
                        CreditCardTypeID,
                        PaymentType,
                        ImageName,
                        MinDeposit,
                        MaxDeposit,
                        ProcessorID
                    };
                }
            });
            await setArrayDeposits(filtered.filter(function (element) { return element !== undefined; }));
            setIsLoading(false);
        }).catch(function (error) {
            setIsLoading(false);
            clientInfo.Error = false;
            Alerts.ShowAlert('Error', 'Error getting deposit limits info: ' + error.response.data.Authentication.ErrorDescription, 'error');
            navigate('/main/');

        })
    }

    function GetFormURL(Key) {
        if (arrayDeposits[Key].PaymentType.toLowerCase() === "credit card")
            return "/deposits/creditcard/" + arrayDeposits[Key].DepositID
        else if (arrayDeposits[Key].PaymentType.toLowerCase() === "crypto")
            return "/deposits/crypto/" + arrayDeposits[Key].DepositID
        else if (arrayDeposits[Key].PaymentType.toLowerCase() === "ewallet")
            switch (arrayDeposits[Key].ImageName.toLowerCase()) {
                case "quickerzelle":
                case "quickervoucher":
                    {
                        return "/deposits/zelle/" + arrayDeposits[Key].DepositID;
                        break;
                    }
                default: {
                    return "/deposits/ewallet/" + arrayDeposits[Key].DepositID;
                    break;
                }
            }
        else if (arrayDeposits[Key].PaymentType.toLowerCase() === "money gram")
            return "/deposits/moneygram/"
        else
            return "";
    }

    if (isLoading) {
        return <Loading />;
    }

    const handlePayoutSelect = (name) => {
        navigate(`/payouts/${name}`);
    }

    const handleExpressSelect = () => {
        navigate(`/main/`);
    }


    if (arrayDeposits.length === 0) {
        navigate('/main/');
    }

    localStorage.setItem('ArrayRegularMethods', JSON.stringify(arrayDeposits));

    return (
        <div className="mt-3 componentload">
            <div className="row">
                <div className="col-12">
                    <hr />
                </div>
            </div>
            <div className="title">
                <div className="container">
                    <h4 className="txt-primary text-title-color"></h4>
                </div>
            </div>
            <div className="row">
                {(disableExpress == false) ?
                    <div className="col-xxl-6 col-md-6 col-sm-6 col-xs-6">
                        <div onClick={() => handleExpressSelect()} className="box-deposit express-color mb-4 text-center text-wrap" >
                            <span className="othermethod-text text-center">EXPRESS DEPOSIT</span>
                        </div>
                    </div>
                    :
                    <div className="col-xxl-6 col-md-6 col-sm-6 col-xs-6" title="You need to deposit first to have this option available">
                        <div className="box-deposit express-color-disabled mb-4 text-center text-wrap" >
                            <span className="othermethod-text text-center">EXPRESS DEPOSIT</span>
                        </div>
                    </div>
                }
                <div className="col-xxl-6 col-md-6 col-sm-6 col-xs-6">
                    <div onClick={() => handlePayoutSelect('')} className="box-deposit payoutmethod-color mb-4 text-center text-wrap" >
                        <span className="othermethod-text text-center">WITHDRAWAL OPTIONS</span>
                    </div>

                </div>
            </div>
            <div className="row">
                {arrayDeposits.map((item) => (
                    <div className="col-xxl-4 col-md-4 col-sm-6 col-xs-6">
                        <Link key={item.Key} to={GetFormURL(item.Key)} className="box-deposit  mb-4">
                            <div className="row">
                                <div className="col-md-3 col-sm-12">
                                    <span className="icon-single">
                                        <img alt="" src={"/svg/" + item.ImageName + ".svg"} />
                                    </span>
                                </div>
                                <div className="col-md-8 col-sm-12 text-center limit-text">
                                    <span>Min:${item.MinDeposit === 0 ? 1 : item.MinDeposit} - Max:{item.MaxDeposit === 0 ? 'No Limit' : '$' + item.MaxDeposit}</span>
                                </div>
                                <div className="col-md-3 limit-text d-none d-md-block ">

                                </div>
                            </div>
                        </Link>
                    </div>
                ))}


            </div>
        </div>
    );
}