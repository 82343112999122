import { Footer } from "../components/Footer"
import { PriorityMethods } from "../components/deposit/PriorityMethods"
import { RegularMethods } from "../components/deposit/RegularMethods"

export const DepositsPage = () => {
    return (
        <div className="container">
            
            <PriorityMethods />
            <RegularMethods />
            <Footer />
        </div>
    );
}