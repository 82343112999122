import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import clientInformation from '../login/ClientInfo';
import Alerts from '../helpers/Alerts';
import { IsValidToken, GetTransactionHistory } from "../../services/AuthenticationService";
import { CancelPayout } from "../../services/PayoutService";
import Loading from '../helpers/Loader';

export const PayoutDetails = () => {
    const clientInfo = clientInformation();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [arrayPayouts, setArrayPayouts] = useState([]);

    useEffect(() => {

        IsValidToken(localStorage.getItem('Token')).then(async function (response) {
            await TransactionHistory()
        }).catch(function (error) {
            navigate('/expired/');
        })

    }, []);

    async function TransactionHistory() {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);


        var historyData = {
            "Token": localStorage.getItem('Token'),
            "CurrencyCode": clientInfo.CurrencyCode,
            "Payment": "-1",
            "DateFrom": firstDay,
            "DateTo": lastDay,
            "Status": "-1",
            "Trace": "",
            "TransactionID": "",
            "TransactionType": 500320
        };

        await GetTransactionHistory(historyData).then(async function (response) {
            await setArrayPayouts(response.Authentication.TransactionHistoryList);
            setIsLoading(false);
        }).catch(function (error) {
            setIsLoading(false);
            clientInfo.Error = false;
            Alerts.ShowAlert('Withdrawal history notice', error.response.data.Authentication.ErrorDescription, 'error');
        })
    }

    async function handleCancel(TransactionID) {
        await CancelPayout(localStorage.getItem('Token'), TransactionID).then(async function (response) {
            Alerts.ShowAlert('Withdrawal cancel notice', response.Authentication.HtmlResponse, 'warning');
            await TransactionHistory()
        }).catch(function (error) {
            Alerts.ShowAlert('Withdrawal', error.response.data.Authentication.ErrorDescription, 'error');
        })
    }

    function getBadgeColor(StatusName) {
        switch (StatusName.toLowerCase()) {
            case "requested":
                return "bg-warning"
            case "authorized":
                return "bg-info"
            case "declined":
            case "rejected":
            case "cancelled by customer":
                return "bg-danger"
            case "approved":
            case "posted":
            case "pay":
                return "bg-success"
        }
    }

    function getProcessPercentage(StatusName) {
        switch (StatusName.toLowerCase()) {
            case "requested":
            case "pending":
                return "40"
            case "authorized":
                return "60"
            case "inprogress":
            case "in-progress":
                return "80"
            default:
                return "100";
        }
    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="mt-5 componentload">
            <div className="card shadow">
                <div className="card-header border-0">
                    <h4 className="mb-0">Current Month Withdrawals</h4>
                </div>
                <div className="table-responsive">
                    <table className="table align-items-center table-font text-center">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col" className="d-none d-md-table-cell">Processor</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Status</th>
                                <th scope="col">TransactionID</th>
                                <th scope="col" className="d-none d-md-table-cell">Completion</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {arrayPayouts.map((item) => (
                                <tr>
                                    <th scope="row" className="d-none d-md-table-cell">
                                        <div className="media align-items-center">
                                            <a href="#" className="avatar mr-2">
                                                {(item.PaymentMethod == 'Generic') ?
                                                    <img alt="" src={"data:image/svg+xml;base64," + item.ImageName} style={{ width: "50px", height: "50px" }} />
                                                    :
                                                    <img alt="none" src={"/svg/" + item.ImageName + ".svg"} style={{ width: "50px", height: "50px" }} />
                                                }
                                            </a>
                                            <div className="media-body">
                                                <span className="mb-0 text-sm">{item.PayoutID}</span>
                                            </div>
                                        </div>
                                    </th>
                                    <td>
                                        {item.CurrencySymbol}&nbsp;{item.Amount}
                                    </td>
                                    <td>
                                        <span className="badge badge-dot">
                                            <i className={getBadgeColor(item.StatusName)}></i> {item.StatusName}
                                        </span>
                                    </td>
                                    <td>
                                        <div>
                                            {item.TransactionID}
                                        </div>
                                    </td>
                                    <td className="d-none d-md-table-cell">
                                        <div className="d-flex align-items-center">
                                            <span className="mr-2">{getProcessPercentage(item.StatusName)}%</span>
                                            <div>
                                                <div className="progress">
                                                    <div className={"progress-bar " + getBadgeColor(item.StatusName)} role="progressbar" aria-valuenow={getProcessPercentage(item.StatusName)}
                                                        aria-valuemin="0" aria-valuemax="100" style={{ width: (getProcessPercentage(item.StatusName) + "%") }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-right">
                                        {
                                            (item.StatusName === "Requested") ?
                                                <img style={{ width: "40px", height: "40px", cursor: "pointer" }} className="button " src="/svg/delete.svg" onClick={() => handleCancel(item.TransactionID)} />
                                                :
                                                ""
                                        }

                                    </td>
                                </tr>

                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="card-footer">
                    &nbsp;
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mb-3-small mt-4">
                    <button type="button" className="btn btn-secondary btn-gray btn-back-order mb-4" onClick={() => navigate('/payouts/')}>BACK</button>
                </div>
            </div>
        </div>
    )
}