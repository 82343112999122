const clientInformation = () => {

    clientInformation.languaje = "";
    clientInformation.Password = "";
    clientInformation.Error = true;
    clientInformation.Udf1 = "";
    clientInformation.IPAddress = "";

    clientInformation.Name = localStorage.getItem('Name');
    clientInformation.LastName = localStorage.getItem('LastName');

    clientInformation.CurrencyCode = localStorage.getItem('CurrencyCode');
    clientInformation.AvailableBalance = localStorage.getItem('AvailableBalance');
    clientInformation.IPAddress = localStorage.getItem('IPAddress');

    clientInformation.CustomerPIN = localStorage.getItem('User');

    return clientInformation;
}

clientInformation.funcMapCustomer = async (data) => {
    localStorage.setItem('Name', await data.Authentication.Name);
    localStorage.setItem('LastName', await data.Authentication.LastName);
}

clientInformation.funcMapBalance = async (data) => {
    localStorage.setItem('AvailableBalance', await data.Authentication.AvailableBalance);
    localStorage.setItem('CurrencyCode', await data.Authentication.CurrencyCode);
}

export default clientInformation;

