import CSSProperties from 'react';
import BeatLoader from "react-spinners/BeatLoader"

function Loading() {


    return (
        <div className="loader-fullcontainer">
            <div className="loader-container">
                <BeatLoader color="#36d7b7" size={45} />
            </div>
        </div>
    );
}

export default Loading;